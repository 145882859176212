/*!
 * smartbanner.js v1.10.1 <https://github.com/ain/smartbanner.js>
 * Copyright © 2018 Ain Tohvri, contributors. Licensed under GPL-3.0.
 */
 .smartbanner {
    position: fixed;
    z-index: 200;
    top: 0; 
    left: 0;
    overflow-x: hidden;
    width: 100%;
    height: 84px;
    background: #f3f3f3;
    /** Android styles **/ }
    .smartbanner__exit {
      position: absolute;
      top: calc(50% - 6px);
      left: 9px;
      display: block;
      margin: 0;
      width: 12px;
      height: 12px;
      border: 0;
      text-align: center; }
      .smartbanner__exit::before, .smartbanner__exit::after {
        position: absolute;
        width: 1px;
        height: 12px;
        background: #767676;
        content: ' '; }
      .smartbanner__exit::before {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .smartbanner__exit::after {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
    .smartbanner__icon {
      position: absolute;
      top: 10px;
      left: 30px;
      width: 64px;
      height: 64px;
      border-radius: 15px;
      background-size: 64px 64px; }
    .smartbanner__info {
      position: absolute;
      top: 5px;
      left: 104px;
      display: flex;
      overflow-y: hidden;
      width: 50%;
      height: 74px;
      align-items: center;
      color: #000; }
      .smartbanner__info__title {
        font-size: 14px; }
      .smartbanner__info__author, .smartbanner__info__price {
        font-size: 13px;
        line-height: 17px;
      }
    .smartbanner__button {
      position: absolute;
      top: 32px;
      right: 10px;
      z-index: 1;
      display: block;
      padding: 0 10px;
      min-width: 10%;
      border-radius: 5px;
      background: #f3f3f3;
      color: #1474fc;
      font-size: 18px;
      text-align: center;
      text-decoration: none; }
      .smartbanner__button__label {
        text-align: center;
        // text-decoration: underline;
        // color: #ba9e66;
        font-size: 14px;
      }
    .smartbanner.smartbanner--android {
      background: #3d3d3d url(data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7);
      box-shadow: inset 0 4px 0 #88b131; }
      .smartbanner.smartbanner--android .smartbanner__exit {
        left: 6px;
        margin-right: 7px;
        width: 17px;
        height: 17px;
        border-radius: 14px;
        background: #1c1e21;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3);
        color: #b1b1b3;
        font-family: 'ArialRoundedMTBold', Arial;
        font-size: 20px;
        -webkit-font-smoothing: subpixel-antialiased;
        line-height: 17px;
        text-shadow: 0 1px 1px #000; }
        .smartbanner.smartbanner--android .smartbanner__exit::before, .smartbanner.smartbanner--android .smartbanner__exit::after {
          top: 3px;
          left: 8px;
          width: 2px;
          height: 11px;
          background: #b1b1b3; }
        .smartbanner.smartbanner--android .smartbanner__exit:active, .smartbanner.smartbanner--android .smartbanner__exit:hover {
          color: #eee; }
      .smartbanner.smartbanner--android .smartbanner__icon {
        background-color: transparent;
        box-shadow: none; }
      .smartbanner.smartbanner--android .smartbanner__info {
        color: #ccc;
        text-shadow: 0 1px 2px #000; }
        .smartbanner.smartbanner--android .smartbanner__info__title {
          color: #fff;
          font-weight: bold; }
      .smartbanner.smartbanner--android .smartbanner__button {
        top: 30px;
        right: 20px;
        padding: 0;
        min-width: 12%;
        border-radius: 0;
        background: none;
        box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
        color: #fff;
        font-size: 14px;
        font-weight: bold; }
        .smartbanner.smartbanner--android .smartbanner__button:active, .smartbanner.smartbanner--android .smartbanner__button:hover {
          background: none; }
        .smartbanner.smartbanner--android .smartbanner__button__label {
          display: block;
          padding: 0 10px;
          background: #ba9e66;
          background: linear-gradient(to bottom, #ba9e66, #ba9e66);
          box-shadow: none;
          line-height: 24px;
          text-align: center;
          text-shadow: none;
          text-transform: none; }
          .smartbanner.smartbanner--android .smartbanner__button__label:active, .smartbanner.smartbanner--android .smartbanner__button__label:hover {
            background: #2ac7e1; }
            
  @media only screen and (max-width: 1100px) and (min-width: 480px)  {
    .smartbanner__info {
      width: 65%;
    }
  }